import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Box } from "@mui/material";

export default function PlannerTimeline() {
  return (
    <Box>
      <Timeline >
        <TimelineItem sx={{ display: "flex", justifyContent: "left" }}>
          <TimelineSeparator>
            <TimelineDot color="secondary" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Box
              sx={{ height: "700px", width: "100%", bgcolor: "#effdff" }}
            ></Box>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="success" />
          </TimelineSeparator>
          <TimelineContent>Success</TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  );
}
