import React, { useEffect, useState } from "react";
import Navbar from "src/Component/Navbar";
import CountryRouteContainer from "src/Component/grid-component/CountryRouteContainer";
import CountryInfoPage from "./CountryInfo/CountryInfo";
import StudyPlaces from "./CountryInfo/StudyPlaces";
import StudyUniversities from "./CountryInfo/StudyUniversities";
import Footer from "src/Component/Footer";
import LetsGetStarted from "src/Component/LetsGetStarted";
import { useParams } from "react-router";
import TestFaq from "src/TestPages/TestFaq";
import ScrollableTabsButtonPrevent from "./CountryInfo/Tabbar";
import StudyUSA from "./CountryInfo/StudyUSA";
import StudyRecruiters from "./CountryInfo/StudyRecruiters";
import {
  UKInfo,
  USAInfo,
  IrelandInfo,
  CanadaInfo,
  GermanyInfo,
  AustraliaInfo,
  SingaporeInfo,
  ItalyInfo,
  FranceInfo,
  DubaiInfo
} from "./CountryInfo/CountryInfoJson/CountryInfoData";
import {
  AustraliaWhyStudy,
  CanadaWhyStudy,
  DubaiWhyStudy,
  FranceWhyStudy,
  GermanyWhyStudy,
  IrelandWhyStudy,
  ItalyWhyStudy,
  SingaporeWhyStudy,
  UKWhyStudy,
  USAWhyStudy
} from "./CountryInfo/CountryInfoJson/WhyStudyData";
import {
  AustraliaPlace,
  CanadaPlace,
  DubaiPlace,
  FrancePlace,
  GermanyPlace,
  IrelandPlace,
  ItalyPlace,
  SingaporePlace,
  UKPlace,
  USAPlace
} from "./CountryInfo/CountryInfoJson/CountryPlaces";
import {
  AustraliaPopular,
  CanadaPopular,
  DubaiPopular,
  FrancePopular,
  GermanyPopular,
  IrelandPopular,
  ItalyPopular,
  SingaporePopular,
  UKPopular,
  USAPopular
} from "./CountryInfo/CountryInfoJson/CountryPopular";
import {
  AustraliaIntake,
  CanadaIntake,
  DubaiIntake,
  FranceIntake,
  GermanyIntake,
  IrelandIntake,
  ItalyIntake,
  SingaporeIntake,
  UKIntake,
  USAIntake
} from "./CountryInfo/CountryInfoJson/CountryIntakeData";
import {
  AustraliaCost,
  CanadaCost,
  DubaiCost,
  FranceCost,
  GermanyCost,
  IrelandCost,
  ItalyCost,
  SingaporeCost,
  UKCost,
  USACost
} from "./CountryInfo/CountryInfoJson/CountryCostData";
import {
  AustraliaVisa,
  CanadaVisa,
  DubaiVisa,
  FranceVisa,
  GermanyVisa,
  IrelandVisa,
  ItalyVisa,
  SingaporeVisa,
  UKVisa,
  USAVisa
} from "./CountryInfo/CountryInfoJson/CountryVisaData";
import {
  AustraliaFaq,
  CanadaFaq,
  DubaiFaq,
  FranceFaq,
  GermanyFaq,
  IrelandFaq,
  ItalyFaq,
  SingaporeFaq,
  UKFaq,
  USAFaq
} from "./CountryInfo/CountryInfoJson/CountryFaqData";
import {
  AustraliaPost,
  CanadaPost,
  DubaiPost,
  FrancePost,
  GermanyPost,
  IrelandPost,
  ItalyPost,
  SingaporePost,
  UKPost,
  USAPost
} from "./CountryInfo/CountryInfoJson/CountryPostData";
import {
  AustraliaRecruiter,
  CanadaRecruiter,
  DubaiRecruiter,
  FranceRecruiter,
  GermanyRecruiter,
  IrelandRecruiter,
  ItalyRecruiter,
  SingaporeRecruiter,
  UKRecruiter,
  USARecruiter
} from "./CountryInfo/CountryInfoJson/CountryRecruitersData";
import {
  AustraliaUniversities,
  CanadaUniversities,
  DubaiUniversities,
  FranceUniversities,
  GermanyUniversities,
  IrelandUniversities,
  ItalyUniversities,
  SingaporeUniversities,
  UKUniversities,
  USAUniversities
} from "./CountryInfo/CountryInfoJson/CountryUniversityData";
import { Helmet } from "react-helmet";

const jsonData = [
  {
    pathname: "USA",
    info: USAInfo,
    whyStudy: USAWhyStudy,
    universties: USAUniversities,
    places: USAPlace,
    popular: USAPopular,
    intake: USAIntake,
    cost: USACost,
    visa: USAVisa,
    post: USAPost,
    recruiter: USARecruiter,
    faq: USAFaq
  },
  {
    pathname: "UK",
    info: UKInfo,
    whyStudy: UKWhyStudy,
    universties: UKUniversities,

    places: UKPlace,
    popular: UKPopular,
    cost: UKCost,
    intake: UKIntake,
    visa: UKVisa,
    post: UKPost,
    recruiter: UKRecruiter,
    faq: UKFaq
  },
  {
    pathname: "Ireland",
    info: IrelandInfo,
    whyStudy: IrelandWhyStudy,
    universties: IrelandUniversities,

    places: IrelandPlace,
    popular: IrelandPopular,
    intake: IrelandIntake,
    cost: IrelandCost,
    visa: IrelandVisa,
    post: IrelandPost,
    recruiter: IrelandRecruiter,
    faq: IrelandFaq
  },
  {
    pathname: "Canada",
    info: CanadaInfo,
    whyStudy: CanadaWhyStudy,
    universties: CanadaUniversities,

    places: CanadaPlace,
    popular: CanadaPopular,
    intake: CanadaIntake,
    cost: CanadaCost,
    visa: CanadaVisa,
    post: CanadaPost,
    recruiter: CanadaRecruiter,
    faq: CanadaFaq
  },
  {
    pathname: "Germany",
    info: GermanyInfo,
    whyStudy: GermanyWhyStudy,
    universties: GermanyUniversities,

    places: GermanyPlace,
    intake: GermanyIntake,
    popular: GermanyPopular,
    cost: GermanyCost,
    visa: GermanyVisa,
    post: GermanyPost,
    recruiter: GermanyRecruiter,
    faq: GermanyFaq
  },
  {
    pathname: "Australia",
    info: AustraliaInfo,
    whyStudy: AustraliaWhyStudy,
    universties: AustraliaUniversities,

    places: AustraliaPlace,
    intake: AustraliaIntake,
    cost: AustraliaCost,
    popular: AustraliaPopular,
    visa: AustraliaVisa,
    post: AustraliaPost,
    recruiter: AustraliaRecruiter,
    faq: AustraliaFaq
  },
  {
    pathname: "Singapore",
    info: SingaporeInfo,
    whyStudy: SingaporeWhyStudy,
    universties: SingaporeUniversities,

    places: SingaporePlace,
    popular: SingaporePopular,
    intake: SingaporeIntake,
    cost: SingaporeCost,
    visa: SingaporeVisa,
    post: SingaporePost,
    recruiter: SingaporeRecruiter,
    faq: SingaporeFaq
  },
  {
    pathname: "Italy",
    info: ItalyInfo,
    whyStudy: ItalyWhyStudy,
    universties: ItalyUniversities,

    places: ItalyPlace,
    cost: ItalyCost,
    popular: ItalyPopular,
    intake: ItalyIntake,
    visa: ItalyVisa,
    post: ItalyPost,
    recruiter: ItalyRecruiter,
    faq: ItalyFaq
  },
  {
    pathname: "France",
    info: FranceInfo,
    whyStudy: FranceWhyStudy,
    universties: FranceUniversities,

    places: FrancePlace,
    popular: FrancePopular,
    intake: FranceIntake,
    cost: FranceCost,
    visa: FranceVisa,
    post: FrancePost,
    recruiter: FranceRecruiter,
    faq: FranceFaq
  },
  {
    pathname: "Dubai",
    info: DubaiInfo,
    whyStudy: DubaiWhyStudy,
    universties: DubaiUniversities,

    places: DubaiPlace,
    cost: DubaiCost,
    popular: DubaiPopular,
    intake: DubaiIntake,
    visa: DubaiVisa,
    post: DubaiPost,
    recruiter: DubaiRecruiter,
    faq: DubaiFaq
  }
];

const StudyAbroadCountryInfo = () => {
  const { pageName } = useParams();
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    const currentPageData = jsonData.find((data) => data.pathname === pageName);
    setPageData(currentPageData);
    console.log(currentPageData);
  }, [pageName]);
  return (
    <>
      <Helmet>
        <title>Taksheela Institute: Your Guide to Studying Abroad</title>
        <meta
          name="description"
          content="Explore the best study abroad programs with Taksheela Institute. Benefit from expert counseling, comprehensive information, and dedicated admission support."
        />
        <meta
          name="keywords"
          content="study abroad opportunities in russia, student abroad programs, international study abroad, universities overseas"
        />
      </Helmet>
      <Navbar />
      <CountryRouteContainer />
      <CountryInfoPage data={pageData?.info} />
      <StudyUSA data={pageData?.whyStudy} />
      <StudyPlaces data={pageData?.places} />
      <StudyUniversities data={pageData?.universties} />
      <ScrollableTabsButtonPrevent
        data1={pageData?.popular}
        data2={pageData?.intake}
        data3={pageData?.cost}
        data4={pageData?.post}
        data5={pageData?.visa}
      />
      <StudyRecruiters data={pageData?.recruiter} />
      <TestFaq faqData={pageData?.faq} backgroundColor={"#ffffff"} />
      <LetsGetStarted top={"20px"} />
      <Footer />
    </>
  );
};

export default StudyAbroadCountryInfo;
