import React, { useEffect, useState } from "react";
import Footer from "src/Component/Footer";
import LetsGetStarted from "src/Component/LetsGetStarted";
import Navbar from "src/Component/Navbar";
import { useParams } from "react-router-dom";
import TestFaq from "./TestFaq";
import {
  IeltFAQ,
  GmatFAQ,
  GreFAQ,
  PteFAQ,
  SatFAQ,
  ToeflFAQ
} from "../json/testFaq";
import {
  IeltsPackage,
  GmatPackage,
  PtePackage,
  GrePackage,
  ToeflPackage,
  SatPackage
} from "../json/testPackages";
import {
  IeltsTestInfo,
  GmatTestInfo,
  PteTestInfo,
  GreTestInfo,
  ToeflTestInfo,
  SatTestInfo
} from "../json/TestsInfo";
import {
  IeltsChooseUs,
  GmatChooseUs,
  PteChooseUs,
  GreChooseUs,
  ToeflChooseUs,
  SatChooseUs
} from "../json/TestsWhyChoose";
import {
  IeltsMainHeading,
  GmatMainHeading,
  PteMainHeading,
  GreMainHeading,
  ToeflMainHeading,
  SatMainHeading
} from "../json/TestMainHeading";
import {
  IletExamSchedule,
  GmatExamSchedule,
  PteExamSchedule,
  GreExamSchedule,
  ToeflExamSchedule,
  SatExamSchedule
} from "../json/testsExamSchedule";
import TestPackages from "./TestPackage";
import TestInfo from "./TestInfo";
import ChooseReason from "./ChooseReason";
import TestRouteContainer from "src/Component/grid-component/TestRouteContainer";
import TestExamStructure from "./TestExamStructure";
import { Helmet } from "react-helmet";
const jsonData = [
  {
    pathname: "ielts",
    testInfo: IeltsTestInfo,
    header: IeltsMainHeading,
    assessible: IletExamSchedule,
    reason: IeltsChooseUs,
    package: IeltsPackage,
    faq: IeltFAQ
  },
  {
    pathname: "gmat",
    testInfo: GmatTestInfo,
    header: GmatMainHeading,
    assessible: GmatExamSchedule,
    reason: GmatChooseUs,
    package: GmatPackage,
    faq: GmatFAQ
  },
  {
    pathname: "gre",
    testInfo: GreTestInfo,
    header: GreMainHeading,
    assessible: GreExamSchedule,
    reason: GreChooseUs,
    package: GrePackage,
    faq: GreFAQ
  },
  {
    pathname: "sat",
    testInfo: SatTestInfo,
    header: SatMainHeading,
    assessible: SatExamSchedule,
    reason: SatChooseUs,
    package: SatPackage,
    faq: SatFAQ
  },
  {
    pathname: "pte",
    testInfo: PteTestInfo,
    header: PteMainHeading,
    assessible: PteExamSchedule,
    reason: PteChooseUs,
    package: PtePackage,
    faq: PteFAQ
  },
  {
    pathname: "toefl",
    testInfo: ToeflTestInfo,
    header: ToeflMainHeading,
    assessible: ToeflExamSchedule,
    reason: ToeflChooseUs,
    package: ToeflPackage,
    faq: ToeflFAQ
  }
];
const TestPages = ({ setItemUpdate, itemupdate }) => {
  const { pageName } = useParams();
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    const currentPageData = jsonData.find((data) => data.pathname === pageName);
    setPageData(currentPageData);
    console.log(currentPageData);
  }, [pageName]);
  return (
    <>
      <Helmet>
        <title>Best Test Preparation Services | Taksheela Institute</title>
        <meta
          name="description"
          content="Excel in your exams with Taksheela Institute's expert test preparation services. Get comprehensive study materials, personalized coaching, and practice tests."
        />
        <meta
          name="keywords"
          content="Best place for TOEFL Preparation, TOEFL Preparation instityute Kolkata, Best place for IELTS Exam Prep, best test Preparation Services center Delhi,"
        />
      </Helmet>
      <Navbar setItemUpdate={setItemUpdate} itemupdate={itemupdate} />
      {/* <TestFormBanner bannerData={pageData?.header} /> */}
      <TestInfo testInfo={pageData?.testInfo} />
      {/* <TestAssessible testAssessible={pageData?.assessible} /> */}
      <TestExamStructure testAssessible={pageData?.assessible} />
      {/* <TestExam examData={pageData.exam} /> */}
      <ChooseReason chooseus={pageData?.reason} />
      <TestPackages
        PackageData={pageData?.package}
        setItemUpdate={setItemUpdate}
        itemupdate={itemupdate}
      />
      <TestFaq faqData={pageData?.faq} />
      <TestRouteContainer />
      <LetsGetStarted top={"20px"} />
      <Footer />
    </>
  );
};

export default TestPages;
