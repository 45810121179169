import React from "react";
import { Box } from "@mui/material";

import { Icon } from "@iconify/react";
import MainHeader from "src/Component/content-component/MainHeader";
import CalendarPage from "./components/CalendarComponents";
import PlannerTimeline from "./components/Timeline";

const Planner = () => {
  return (
    <Box sx={{ padding: "20px", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mb: "30px",
          alignItems: "center"
        }}
      >
        <Icon
          icon="streamline:class-lesson"
          style={{ color: "#00999E", fontSize: "28px" }}
        />
        <Box sx={{ pl: "20px" }}>
          <MainHeader
            content={"Planner"}
            fontWeight={"550"}
            fontSize={"28px"}
          />
        </Box>
      </Box>
      <Box>
        <CalendarPage />
      </Box>
      <Box sx={{ m: "20px 0" }}>
        <PlannerTimeline />
      </Box>
    </Box>
  );
};

export default Planner;
