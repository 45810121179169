import React, { useState } from "react";
import Home from "./Component/Home";
import About from "./Component/AboutUs";
import Contact from "./Component/ContactUs";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginSignUp from "./Component/login-signup/LoginSignup";
import MBBS from "./Component/MBBS";
import MbbsIndia from "./Component/MbbsIndia";
import MbbsAbroad from "./Component/MbbsAbroad";
import Immigration from "./Component/Immigration";
import Test from "./Component/Test";
import TestPages from "./TestPages/TestPages";
import PrivacyPolicy from "./PrivacyPages/PrivacyPolicy";
import RefundPolicy from "./PrivacyPages/RefundPolicy";
import TermsAndConditions from "./PrivacyPages/TermsAndCondition";
import DashboardLayout from "./Dashboard/DashboardLayout";
import DashboardHome from "./Dashboard/DashboardHome";
import StudyAbroad from "./CountryPages/StudyAbroad";
import StudyAbroadMainPage from "./CountryPages/StudyAbroadMainPage";
import StudyAbroadCountryInfo from "./CountryPages/StudyAbroadCountryInfo";
import MbbsCountryMainpage from "./MBBSCountries/MbbsCountryMainPage";
import MbbsCountryMainPage from "./MBBSCountries/MbbsCountryMainPage";
import AddCart from "./Cart/AddCart";
import DashboardOrder from "./Dashboard/DashboardOrder";
import OrderStatus from "./Cart/SuccessPage";
import ThankYouPage from "./Component/thankYouPage";
import DashboardOrderDetail from "./Dashboard/DashboardOrderDetail";
import DashboardEnrollmentForm2 from "./Dashboard/Enrollment/DashboardEnrollmentForm2";
import DashboardPreCounsellingForm from "./Dashboard/DashboardPreCounsellingForm";
import DashboardEnrollmentStepper from "./Dashboard/Enrollment/DashboardStepper";
import ForgetPassword from "./Component/login-signup/ForgetPassword";
import ResetPassword from "./Component/login-signup/ResetPassword";
// import SelectUniversities from "./CountryPages/University/SelectUniversities";
import ImmigrationCountryPage from "./immigration/ImmigrationCountryPage";
import UniversityPage from "./CountryPages/UniversityPage";
import NotFound from "./Component/NotFound";
import SelectCourses from "./CountryPages/Courses/SelectCourses";
import CoursePage from "./CountryPages/CoursePage";
import TestPrepHome from "./Dashboard/TestPrepHome/TestPrepHome";
import LearningResources from "./Dashboard/LearningResources/LearningResources";
import MockTest from "./Dashboard/MockTest/MockTest";
import Support from "./Dashboard/Support/support";
import UniversityFinder from "./UniversityFinder/UniversityFinder";
import UniversityView from "./UniversityFinder/UniversityView";
import UniversityCompare from "./UniversityFinder/universityCompare";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Performances from "./Dashboard/Performance/Performances";
import Planner from "./Dashboard/Planner/Planner";
import ComingSoon from "./Component/ComingSoon";
import SelectUniversities from "./CountryPages/University/SelectUniversities";

const App = () => {
  console.log("qwertyuiop", `${process.env.REACT_APP_BASE_URL}`);

  const [itemupdate, setItemUpdate] = useState(false);
  return (
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="login" element={<LoginSignUp />} />
        <Route path="signup" element={<LoginSignUp />} />
        <Route path="forgetpassword" element={<ForgetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="aboutus" element={<About />} />
        <Route path="contact" element={<Contact />} />
        {/* <Route path="services" element={<Services />} /> */}
        <Route path="mbbs" element={<MBBS />} />
        <Route path="immigration" element={<Immigration />} />
        <Route
          path="immigration/:pageName"
          element={<ImmigrationCountryPage />}
        />
        <Route path="mbbs/india" element={<MbbsIndia />} />
        <Route path="mbbs/abroad" element={<MbbsAbroad />} />
        <Route path="mbbs/abroad/:pageName" element={<MbbsCountryMainPage />} />
        <Route path="test" element={<Test />} />
        <Route
          path="test/:pageName"
          element={
            <TestPages setItemUpdate={setItemUpdate} itemupdate={itemupdate} />
          }
        />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="refund-policy" element={<RefundPolicy />} />
        <Route path="terms-&-conditions" element={<TermsAndConditions />} />
        <Route path="dashboard" element={<DashboardLayout />}>
          <Route path="enrollment" index element={<DashboardHome />} />
          <Route path="enrollment/home" index element={<TestPrepHome />} />
          <Route path="order" element={<DashboardOrder />} />
          <Route path="learningresources" element={<LearningResources />} />
          <Route path="mocktest" element={<MockTest />} />
          <Route path="support" element={<Support />} />
          <Route path="performance" element={<Performances />} />
          <Route path="planner" element={<Planner />} />
          <Route
            path="order/order-details/:id"
            element={<DashboardOrderDetail />}
          />
          <Route
            path="enrollment/enrollment-form"
            element={<DashboardEnrollmentStepper />}
          />
          <Route
            path="enrollment/enrollment-form/2"
            element={<DashboardEnrollmentForm2 />}
          />
          <Route
            path="precounselling-form"
            element={<DashboardPreCounsellingForm />}
          />
        </Route>
        <Route path="study-abroad" element={<StudyAbroadMainPage />} />
        <Route path="study-abroad/country" element={<StudyAbroad />} />
        <Route
          path="study-abroad/country/:pageName"
          element={<StudyAbroadCountryInfo />}
        />
        <Route
          path="study-abroad/universities"
          element={<SelectUniversities />}
          // element={<UniversityFinder />}
        />
        <Route
          path="study-abroad/universities/view/Compare"
          element={<UniversityCompare />}
        />
        <Route
          path="study-abroad/universities/view"
          element={<UniversityView />}
        />
        <Route
          path="study-abroad/universities/:pageName"
          element={<UniversityPage />}
        />

        <Route path="study-abroad/courses" element={<SelectCourses />} />
        <Route path="study-abroad/courses/:pageName" element={<CoursePage />} />
        <Route path="mbbs/Countries" element={<MbbsCountryMainpage />} />
        <Route
          path="addCart"
          element={
            <AddCart setItemUpdate={setItemUpdate} itemupdate={itemupdate} />
          }
        />
        <Route path="order-success" element={<OrderStatus />} />
        <Route path="thank-you" element={<ThankYouPage />} />
        <Route path="coming-soon" element={<ComingSoon />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
