import { Box, Typography } from "@mui/material";
import React from "react";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import MainHeader from "src/Component/content-component/MainHeader";
import calendarImg from "../../../../asset/TestPrepDashboard/calendartop.png";
import calendaricon from "../../../../asset/TestPrepDashboard/calendaricon.png";
import videoicon from "../../../../asset/TestPrepDashboard/video.png";

const Schedule = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        mb: "20px"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        <MainHeader
          content={"Exam Date"}
          fontWeight={"500"}
          fontSize={"24px"}
        />
        <Box
          sx={{
            mt: "20px",
            width: "320px",
            height: "320px",
            border: "1px solid #B9B9B9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
            flexDirection: "column",
            overflow: "hidden"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "186px",
              margin: "auto "
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "60px",
                backgroundImage: `url(${calendarImg})`
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "32px",
                  fontWeight: "600",
                  textAlign: "center"
                }}
              >
                July
              </Typography>
            </Box>
            <Box
              sx={{
                border: "1px solid #BDBDBD",
                height: "100px",
                margin: "0 3px 3px 3px",
                borderRadius: "0px 0px 10px 10px"
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontSize: "52px",
                  fontWeight: "600",
                  textAlign: "center"
                }}
              >
                17
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              border: "1px solid #B9B9B9",
              mt: "auto",
              height: "40px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "10px"
            }}
          >
            <img src={calendaricon} alt="" />
            <Typography
              sx={{ color: "#00999E", fontWeight: "500", pl: "15px" }}
            >
              24 days{" "}
            </Typography>
            <Typography sx={{ color: "#777777", fontWeight: "500", pl: "5px" }}>
              {" "}
              to go{">>>>>>"}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          pl: "20px",
          flexDirection: "column"
        }}
      >
        <MainHeader
          content={"Upcoming Classes"}
          fontWeight={"500"}
          fontSize={"24px"}
        />
        <Box
          sx={{
            mt: "20px",
            width: "100%",
            height: "330px",
            border: "1px solid #B9B9B9",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "110px",
              border: "1px solid #B9B9B9",
              display: "flex",
              flexDirection: "row",
              padding: "0 20px",
              alignItems: "center"
            }}
          >
            <img src={videoicon} alt="" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "0 20px"
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#4C4C4C", fontWeight: "500" }}
              >
                Grammar Classes for IELTS
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: "#838383", fontWeight: "500" }}
              >
                24 June 2024, 9:00 AM - 11:00 PM{" "}
              </Typography>
            </Box>
            <Box sx={{ ml: "auto" }}>
              <ButtonComponent
                width={"150px"}
                text={"Join"}
                fontWeight={"550"}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "110px",
              border: "1px solid #B9B9B9",
              display: "flex",
              flexDirection: "row",
              padding: "0 20px",
              alignItems: "center"
            }}
          >
            <img src={videoicon} alt="" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "0 20px"
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#4C4C4C", fontWeight: "500" }}
              >
                Grammar Classes for IELTS
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: "#838383", fontWeight: "500" }}
              >
                24 June 2024, 9:00 AM - 11:00 PM{" "}
              </Typography>
            </Box>
            <Box sx={{ ml: "auto" }}>
              <ButtonComponent
                width={"150px"}
                text={"Join"}
                fontWeight={"550"}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "110px",
              border: "1px solid #B9B9B9",
              display: "flex",
              flexDirection: "row",
              padding: "0 20px",
              alignItems: "center"
            }}
          >
            <img src={videoicon} alt="" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "0 20px"
              }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#4C4C4C", fontWeight: "500" }}
              >
                Grammar Classes for IELTS
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{ color: "#838383", fontWeight: "500" }}
              >
                24 June 2024, 9:00 AM - 11:00 PM{" "}
              </Typography>
            </Box>
            <Box sx={{ ml: "auto" }}>
              <ButtonComponent
                width={"150px"}
                text={"Join"}
                fontWeight={"550"}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Schedule;
