import React from "react";
import { Box, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import logo from "../../../asset/TIE_LOGO.png";

import { useLocation, useNavigate } from "react-router-dom";

const json = [
  {
    title: "Dashboard",
    icon: <Icon icon={"fluent:home-32-regular"} />,
    path: "enrollment"
  },
  {
    title: "Planner",
    icon: <Icon icon={"streamline:class-lesson"} />,
    path: "planner"
  },
  {
    title: "Learning Resources",
    icon: <Icon icon={"grommet-icons:resources"} />,
    path: "learningresources"
  },
  {
    title: "Mock Test",
    icon: <Icon icon={"heroicons:light-bulb"} />,
    path: "mocktest"
  },
  {
    title: "Performance",
    icon: <Icon icon={"game-icons:progression"} />,
    path: "performance"
  },
  {
    title: "Support",
    icon: <Icon icon={"ic:baseline-contact-phone"} />,
    path: "support"
  },
  {
    title: "Order",
    icon: <Icon icon={"ion:cart-outline"} />,
    path: "order"
  }
];

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Box sx={{ display: { md: "block", xs: "none" } }}>
      <Box
        sx={{
          height: "100%",
          backgroundColor: "#FFFFFF",
          padding: "10px 5px",
          borderRight: "1px solid #ccc",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Box
          sx={{
            width: "220px"
          }}
        >
          <img src={logo} alt="dashboard-logo" width={"100%"} />
        </Box>
        <List
          sx={{
            marginTop: "20px"
          }}
        >
          {json.map((item, index) => {
            const isSelected = location.pathname.includes(
              item?.path?.toLowerCase()
            );
            return (
              <ListItem
                key={index}
                sx={{
                  margin: "5px 0",
                  padding: "8px 10px",
                  fontWeight: "500",
                  backgroundColor: isSelected ? "#C9EFFB" : "transparent",
                  color: isSelected ? "#000" : "#706C6D",
                  "&:hover": {
                    backgroundColor: "#C9EFFB",
                    color: "#000"
                  },
                  cursor: "pointer",
                  transition: "backgroundColor 0.3s linear",
                  borderRadius: "5px"
                }}
                onClick={() => navigate(`${item?.path?.toLowerCase()}`)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "auto",
                    marginRight: "8px",
                    color: isSelected ? "#000" : "#706C6D"
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <Typography
                  variant="inherit"
                  sx={{ color: isSelected ? "#000" : "#706C6D" }}
                >
                  {item.title}
                </Typography>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

export default NavBar;
