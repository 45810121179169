import { Icon } from "@iconify/react";
import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import MainHeader from "src/Component/content-component/MainHeader";

const data = [
  {
    name: "Mock Test 01",
    overall: 4,
    listening: 9,
    writing: 4,
    speaking: 10,
    grammar: 2
  },
  {
    name: "Mock Test 02",
    overall: 4,
    listening: 9,
    writing: 4,
    speaking: 10,
    grammar: 2
  },
  {
    name: "Mock Test 03",
    overall: 4,
    listening: 9,
    writing: 4,
    speaking: 10,
    grammar: 2
  },
  {
    name: "Mock Test 04",
    overall: 4,
    listening: 9,
    writing: 4,
    speaking: 10,
    grammar: 2
  },
  {
    name: "Mock Test 05",
    overall: 4,
    listening: 9,
    writing: 4,
    speaking: 10,
    grammar: 2
  },
  {
    name: "Mock Test 06",
    overall: 4,
    listening: 9,
    writing: 4,
    speaking: 10,
    grammar: 2
  },
  {
    name: "Mock Test 07",
    overall: 4,
    listening: 9,
    writing: 4,
    speaking: 10,
    grammar: 2
  }

  // Other mock test data
];

const BarGraph = () => {
  const [startIndex, setStartIndex] = useState(0);
  const maxBarsVisible = 5;

  const handleSlide = (direction) => {
    if (direction === "left" && startIndex > 0) {
      setStartIndex(startIndex - 1);
    } else if (
      direction === "right" &&
      startIndex < data.length - maxBarsVisible
    ) {
      setStartIndex(startIndex + 1);
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column", margin: "20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between"
        }}
      >
        <Typography>Detailed Analysis of iELTS PER WEEK</Typography>
        <Box
          sx={{
            border: "2px solid #00999E",
            padding: "5px 10px",
            borderRadius: "20px"
          }}
        >
          <Typography variant="body2">Weekly MT : 6</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          mt: 2
        }}
      >
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            width: "1000px"
          }}
        >
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={data.slice(startIndex, startIndex + maxBarsVisible)}
              // margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="12 0 " />
              <XAxis dataKey="name" />
              <YAxis
                label={{
                  value: "Score 1-10 for IELTS",
                  angle: -90
                }}
                domain={[1, 10]}
                ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              />
              <Tooltip />
              <Bar dataKey="overall" fill="#025356" name="Over All" />
              <Bar dataKey="listening" fill="#0A9DA2" name="Listening" />
              <Bar dataKey="writing" fill="#1DC6CC" name="Writing" />
              <Bar dataKey="speaking" fill="#4FEBF1" name="Speaking" />
              <Bar dataKey="grammar" fill="#B1FCFF" name="Grammar" />
            </BarChart>
          </ResponsiveContainer>

          <MainHeader
            content={"Topic/Week Analysis"}
            color={"#000"}
            textAlign={"center"}
            fontSize={"20px"}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px"
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: "#025356",
                  mr: 1
                }}
              />
              <Typography>Over All</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: "#0A9DA2",
                  mr: 1
                }}
              />
              <Typography>Listening</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: "#1DC6CC",
                  mr: 1
                }}
              />
              <Typography>Writing</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: "#4FEBF1",
                  mr: 1
                }}
              />
              <Typography>Speaking</Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: "#B1FCFF",
                  mr: 1
                }}
              />
              <Typography>Grammar</Typography>
            </Box>
          </Box>

          {/* Slide buttons */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              mt: 2
            }}
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: "10px",
                backgroundColor: "#00999E",
                mr: "20px"
              }}
              onClick={() => handleSlide("left")}
              disabled={startIndex === 0}
            >
              {/* ← */}
              {"<"}------
            </Button>
            <Button
              variant="contained"
              sx={{ borderRadius: "10px", backgroundColor: "#00999E" }}
              onClick={() => handleSlide("right")}
              disabled={startIndex >= data.length - maxBarsVisible}
            >
              {/* → */}
              ------{">"}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "350px",
            bgcolor: "#CBFDFF",
            ml: "20px",
            padding: "20px",
            textAlign: "center",
            alignItems: "center"
          }}
        >
          <MainHeader
            content={"Improvement Plans:"}
            fontSize={"18px"}
            color={"#000"}
          />
          <MainHeader
            content={"Plan Based On the Analysis"}
            fontSize={"14px"}
            color={"#667085"}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px"
            }}
          >
            <MainHeader
              content={"Writing Plan"}
              fontSize={"14px"}
              color={"#667085"}
            />
            <Icon
              icon="flowbite:download-solid"
              color="#667085"
              fontSize={"24px"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px"
            }}
          >
            <MainHeader
              content={"Grammar Plan"}
              fontSize={"14px"}
              color={"#667085"}
            />
            <Icon
              icon="flowbite:download-solid"
              color="#667085"
              fontSize={"24px"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px"
            }}
          >
            <MainHeader
              content={"Listening Plan"}
              fontSize={"14px"}
              color={"#667085"}
            />
            <Icon
              icon="flowbite:download-solid"
              color="#667085"
              fontSize={"24px"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px"
            }}
          >
            <MainHeader
              content={"Speaking Plan"}
              fontSize={"14px"}
              color={"#667085"}
            />
            <Icon
              icon="flowbite:download-solid"
              color="#667085"
              fontSize={"24px"}
            />
          </Box>
          <ButtonComponent
            text={"View Details"}
            width={"100%"}
            height={"50px"}
            fontWeight={"550"}
            icon={<Icon icon="grommet-icons:link-next" />}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BarGraph;
