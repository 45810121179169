import React from "react";
import Navbar from "./Navbar";
import StartJourney from "./StartJourney";
// import TopUniversity from './TopUniversity'
// import Services from './Services'
// import LetsGetStarted from './LetsGetStarted'
// import Milestones from './mildstones/Milestones'
import ReadMoreAboutUs from "./ReadMoreAboutUs";
import Testimonial from "./Testimonial";
import Footer from "./Footer";
import { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import NewsComponent from "src/mbbs/News";
import WebinarEvent from "src/Home/WebinarEvent";
// import NewsUpdate from 'src/Home/NewsUpdate'
import OurServices from "src/Home/OurServices";
import { Helmet } from "react-helmet";
import BrochureVideo from "src/Home/BrochureVideo";
import brochure from "../asset/Taksheela Brochure.pdf";
import { Icon } from "@iconify/react";

const Home = () => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = brochure;
    link.setAttribute("download", "Taksheela Brochure.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Best Study Abroad & Test Prep Institute | Taksheela - Delhi, Kolkata,
          Jaipur
        </title>
        <meta
          name="description"
          content="Taksheela Institute offers top-notch study abroad, immigration, MBBS abroad, and test prep services. Based in Delhi, Kolkata, and Jaipur, we are the best institute for your global education needs. Discover our expert guidance and comprehensive support today!"
        />
        <meta
          name="keywords"
          content="best institute for your global education, Best study abroad institute in kolkata, study abroad in Delhi,"
        />
      </Helmet>
      <Navbar />
      <StartJourney />
      <OurServices />
      {/* <TopUniversity /> */}
      {/* <Milestones /> */}
      {/* <Services /> */}
      <ReadMoreAboutUs />
      <BrochureVideo />
      <Box
        className="custom-link"
        onClick={handleDownload}
        sx={{
          cursor: "pointer",
          margin: "30px 0",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Button
          sx={{
            fontSize: "14px",
            width: "300px",
            height: "50px",
            fontWeight: "600",
            textTransform: "capitalize",
            textAlign: "center",
            color: "#fff",
            borderRadius: "30px",
            backgroundColor: "#00999E",

            transition: "all 0.3s linear",
            border: "2px solid #00999E",
            "&:hover": {
              color: "#000",
              border: "2px solid #000000"
            },
            "& .MuiLoadingButton-loadingIndicator": {
              color: "#000000",
              fontSize: "50px"
            }
          }}
        >
          <Typography
            variant="body2"
            // color="#606060"
            sx={{
              fontWeight: 550,
              fontSize: "16px",
              padding: "5px 4px"
            }}
          >
            Download Brochure
          </Typography>
          <Icon icon="eva:download-fill" fontSize={"22px"} />
        </Button>
      </Box>
      <Box sx={{ margin: "40px 0" }}>
        <Testimonial />
      </Box>
      <WebinarEvent />
      {/* <NewsUpdate /> */}
      <NewsComponent />

      {/* <LetsGetStarted
        top="-40px"
        marginBottom={"10px"}
      /> */}
      <Box sx={{ marginTop: "40px" }}>
        <Footer />
      </Box>
    </>
  );
};

export default Home;
