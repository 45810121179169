import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box
} from "@mui/material";

const DropdownComponent = ({
  label,
  options = [],
  value = "",
  onChange = () => {}
}) => {
  // Check if options are objects or strings
  const isObjectOptions = options.length > 0 && typeof options[0] === "object";

  return (
    <Box>
      <Typography sx={{ mt: "10px" }}>{label}</Typography>
      <FormControl
        fullWidth
        variant="outlined"
        margin="normal"
        sx={{
          border: "none",
          backgroundColor: "white",
          "& .MuiOutlinedInput-root": {
            borderRadius: "4px"
          }
        }}
      >
        <InputLabel>Select {label}</InputLabel>
        <Select
          value={value} // Controlled component
          onChange={(event) => onChange(event.target.value)} // Safely call onChange
          sx={{
            backgroundColor: "white",
            "& .MuiSelect-select": {
              padding: "14px 14px"
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none"
            }
          }}
        >
          <MenuItem value="" disabled>
            {`Select ${label}`}
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={isObjectOptions ? option.value : option}
            >
              {isObjectOptions ? option.label : option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropdownComponent;
