import { Icon } from "@iconify/react";
import { Box, Tabs, Tab, Typography, Button, Grid } from "@mui/material";
import React, { useState } from "react";

// Sample Mock Test Data
const mockTests = [
  {
    testId: 1,
    testName: "Mock Test- 01",
    conductedOn: "June 24, 2024 at 2:23 PM",
    questions: 20,
    marks: 60,
    time: "24 Mins",
    correct: 10,
    unanswered: 4,
    incorrect: 6,
    totalScore: 12,
    totalPossibleScore: 20
  },
  {
    testId: 2,
    testName: "Mock Test- 02",
    conductedOn: "June 25, 2024 at 2:30 PM",
    questions: 25,
    marks: 75,
    time: "30 Mins",
    correct: 15,
    unanswered: 3,
    incorrect: 7,
    totalScore: 17,
    totalPossibleScore: 25
  }
];

const ScoreMarks = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ padding: 2 }}>
      {/* Tabs Section */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="fullWidth"
        TabIndicatorProps={{
          style: { display: "none" } // Hides the default indicator
        }}
        sx={{
          "& .MuiTab-root": {
            backgroundColor: "#EFFDFF",
            borderRadius: "8px",
            height: "30px",
            color: "#00999E", // Selected tab text color
            textTransform: "none",
            fontWeight: "500",
            marginRight: 1
          },
          ".css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected": {
            color: "#ffffff"
          },
          "& .Mui-selected": {
            backgroundColor: "#00999E",
            color: "#FFFFFF"
          }
        }}
      >
        <Tab label="Reading" />
        <Tab label="Speaking" />
        <Tab label="Listening" />
        <Tab label="Writing" />
      </Tabs>

      {/* Content Based on Selected Tab */}
      <Box sx={{ marginTop: 3 }}>
        {mockTests.map((test) => (
          <Box
            key={test.testId}
            sx={{
              padding: 2,
              border: "1px solid #e0e0e0",
              borderRadius: 2,
              mb: 3
            }}
          >
            {/* Test Header */}
            <Box display="flex" flexDirection={"column"} mb={2}>
              <Typography
                variant="h6"
                fontWeight="500"
                sx={{ color: "GrayText" }}
              >
                {test.testName}
              </Typography>
              <Typography variant="body2" sx={{ color: "GrayText" }}>
                Conducted On {test.conductedOn}
              </Typography>
            </Box>

            <Grid container spacing={2} mb={2}>
              <Grid item xs={4} sm={2}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Icon
                    icon="pajamas:question-o"
                    style={{ color: "#80869A", fontSize: "20px" }}
                  />
                  {test.questions} Questions
                </Typography>
              </Grid>
              <Grid item xs={4} sm={2}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Icon
                    icon="mingcute:bookmarks-fill"
                    style={{ color: "#80869A", fontSize: "20px" }}
                  />
                  {test.marks} Marks
                </Typography>
              </Grid>
              <Grid item xs={4} sm={2}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Icon
                    icon="wi:time-1"
                    style={{ color: "#80869A", fontSize: "20px" }}
                  />
                  {test.time}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    sx={{ mr: "10px", bgcolor: "#00999E" }}
                  >
                    Re-attempt test
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ border: "2px solid #00999E", color: "#00999E" }}
                  >
                    View detailed marks
                  </Button>
                </Box>
              </Grid>
            </Grid>

            {/* Overview Section */}
            <Box mb={2}>
              <Typography
                variant="h6"
                fontWeight="500"
                sx={{ color: "GrayText" }}
              >
                Overview
              </Typography>
              <Typography variant="body2" sx={{ color: "GrayText" }}>
                Summary of marks scored in the test attempted on{" "}
                {test.conductedOn}
              </Typography>

              <Grid container spacing={2} mt={2}>
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      padding: 1,
                      borderRadius: 2
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: "GrayText", fontWeight: "500", mb: "10px" }}
                    >
                      Total Correct
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #00999E",
                        borderRadius: "8px",
                        p: "5px 0"
                      }}
                    >
                      <Typography variant="h6" sx={{ color: "#00999E" }}>
                        {test.correct} Points
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      padding: 1,
                      borderRadius: 2
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: "GrayText", fontWeight: "500", mb: "10px" }}
                    >
                      Unanswered
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #F9C45B",
                        borderRadius: "8px",
                        p: "5px 0"
                      }}
                    >
                      <Typography variant="h6" sx={{ color: "#F9C45B" }}>
                        {test.unanswered} Points
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      padding: 1,
                      borderRadius: 2
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: "GrayText", fontWeight: "500", mb: "10px" }}
                    >
                      Incorrect
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #DD5B63",
                        borderRadius: "8px",
                        p: "5px 0"
                      }}
                    >
                      <Typography variant="h6" sx={{ color: "#DD5B63" }}>
                        {test.incorrect} Points
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box
                    sx={{
                      textAlign: "center",
                      padding: 1,
                      borderRadius: 2
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ color: "GrayText", fontWeight: "500", mb: "10px" }}
                    >
                      Total Score
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #00999E",
                        borderRadius: "8px",
                        p: "5px 0"
                      }}
                    >
                      <Typography variant="h6" sx={{ color: "#00999E" }}>
                        {test.totalScore}/{test.totalPossibleScore}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ScoreMarks;
