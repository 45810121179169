import { Box, Typography } from "@mui/material";
import React from "react";

import Header from "src/Component/content-component/Header";

import CustomBarChart from "./Components/progressChart";
import ProfileInfo from "./Components/TestPrepHome/ProfileInfo";
import Schedule from "./Components/TestPrepHome/Schedule";
import FacultyConnectBanner from "./Components/TestPrepHome/FacultyConnectBanner";

const TestPrepHome = () => {
  return (
    <Box sx={{ margin: "0px 20px", display: "flex", flexDirection: "column" }}>
      <ProfileInfo />
      <Schedule />
      <FacultyConnectBanner />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center"
        }}
      >
        <Header content={"Milestones"} />
        <Box
          sx={{ margin: "20px 0", padding: "20px 30px", bgcolor: "#DDFEFF" }}
        >
          <Header content={"My Progress"} fontSize={"24px"} />
          <Box sx={{ padding: "20px 0px 20px 20px" }}>
            <CustomBarChart />
          </Box>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            sx={{ color: "#00999E" }}
          >
            No. of classes / Overall mock test
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TestPrepHome;
