import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import learning from "../../asset/TestPrepDashboard/learning.svg";
import MainHeader from "src/Component/content-component/MainHeader";
import { Icon } from "@iconify/react";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
const bookletsData = [
  {
    date: "Jan 1 2024",
    title: "Weekly Booklet/ Practice Paper",
    booklets: [
      { name: "Booklet 1", viewIcon: "carbon:view-filled" },
      { name: "Booklet 2", viewIcon: "carbon:view-filled" }
    ],
    buttonLabel: "Practice assignment"
  },

  {
    date: "Jan 8 2024",
    title: "Weekly Booklet/ Practice Paper",
    booklets: [
      { name: "Booklet 3", viewIcon: "carbon:view-filled" },
      { name: "Booklet 4", viewIcon: "carbon:view-filled" }
    ],
    buttonLabel: "Practice assignment"
  },
  {
    date: "Jan 8 2024",
    title: "Weekly Booklet/ Practice Paper",
    booklets: [
      { name: "Booklet 3", viewIcon: "carbon:view-filled" },
      { name: "Booklet 4", viewIcon: "carbon:view-filled" }
    ],
    buttonLabel: "Practice assignment"
  },
  {
    date: "Jan 8 2024",
    title: "Weekly Booklet/ Practice Paper",
    booklets: [
      { name: "Booklet 3", viewIcon: "carbon:view-filled" },
      { name: "Booklet 4", viewIcon: "carbon:view-filled" }
    ],
    buttonLabel: "Practice assignment"
  },
  {
    date: "Jan 8 2024",
    title: "Weekly Booklet/ Practice Paper",
    booklets: [
      { name: "Booklet 3", viewIcon: "carbon:view-filled" },
      { name: "Booklet 4", viewIcon: "carbon:view-filled" }
    ],
    buttonLabel: "Practice assignment"
  }
];
const LearningResources = () => {
  return (
    <Box sx={{ padding: "20px", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", mb: "30px" }}>
        <img src={learning} alt="" style={{ fontSize: "48px" }} />
        <Box sx={{ pl: "20px" }}>
          <MainHeader
            content={"Learning Resources"}
            fontWeight={"550"}
            fontSize={"28px"}
          />
        </Box>
      </Box>
      <Grid container spacing={3}>
        {bookletsData.map((item, index) => (
          <Grid
            item
            md={4}
            xs={12}
            key={index}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: "340px",
                border: "1px solid #00999E",
                borderRadius: "20px",
                padding: "10px"
              }}
            >
              <Typography sx={{ color: "#00999E", fontWeight: "500" }}>
                {item.date}
              </Typography>
              <MainHeader
                content={item.title}
                color={"#1a1a1a"}
                fontWeight={"550"}
                fontSize={"24px"}
              />
              {item.booklets.map((booklet, i) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    m: "10px 0"
                  }}
                  key={i}
                >
                  <Icon icon="ri:booklet-fill" style={{ color: "#667085" }} />
                  <Typography
                    sx={{ color: "#667085", fontWeight: "500", pl: "10px" }}
                  >
                    {booklet.name}
                  </Typography>
                  <Box
                    sx={{
                      height: "25px",
                      ml: "auto",
                      border: "1px solid #667085",
                      borderRadius: "5px",
                      padding: "0 10px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center"
                    }}
                  >
                    <Icon
                      icon={booklet.viewIcon}
                      style={{ color: "#667085" }}
                    />
                    <Typography
                      sx={{ color: "#667085", fontWeight: "500", pl: "10px" }}
                    >
                      View
                    </Typography>
                  </Box>
                </Box>
              ))}
              <ButtonComponent
                width={"100%"}
                text={item.buttonLabel}
                icon={
                  <Icon
                    icon="eva:arrow-forward-fill"
                    style={{ color: "#fff" }}
                  />
                }
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LearningResources;
